<template>
  <div class="popup_wrap doc_req_pop" style="width:950px;">
    <form id="frm">
      <button class="layer_close" @click="$emit('close')">
        <span>close</span>
      </button>
      <div class="popup_cont">
        <h1 class="page_title text_center">
          선적 취소 요청서
        </h1>

        <div class="content_box">
          <!-- content_box -->
          <h2 class="content_title">
            1. 요청 개요
          </h2><!-- 스케쥴 -->
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:20%">
              <col style="width:40%">
              <col style="width:40%">
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">
                  요청일
                </th>
                <td colspan="2">{{ today }}</td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center">
                  수신
                </th>
                <td colspan="2">고려해운(주)</td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center" rowspan="2">
                  발신
                </th>
                <td>{{ formData.shprCstEnm }}</td>
                <td>{{ formData.shprCstNm }}</td>
              </tr>
              <tr>
                <td>
                  <input
                    v-model="formData.shprCstTelNo"
                    id="shprCstTelNo"
                    type="text"
                    placeholder="Tel"
                  >
                </td>
                <td>
                  <input
                    v-model="formData.shprCstEml"
                    id="shprCstEml"
                    type="text"
                    placeholder="E-mail"
                    @input="checkErrorVisible('shprCstEml')"
                    @blur="checkValidationEmail('shprCstEml')"
                  >
                </td>
              </tr>
              <tr>
                <th class="text_center" rowspan="2">
                  실화주명
                </th>
                <td colspan="2">{{ formData.actShprCstEnm }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">
            2. B/L 정보
          </h2>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:20%">
              <col style="width:40%">
              <col style="width:40%">
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">
                  B/L No.
                </th>
                <td colspan="2">
                  {{ formData.blNo }}
                </td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center">
                  POL/POD
                </th>
                <td colspan="2"> {{ formData.polPlcNm }} / {{ formData.podPlcNm }}</td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center">
                  VSL/VOY
                </th>
                <td colspan="2">{{ formData.vslNm }} / {{ formData.voyNo }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">
            3. 요청 상세
          </h2>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:20%">
              <col style="width:40%">
              <col style="width:40%">
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">
                  요청 사유
                </th>
                <td colspan="2">
                  <input
                    v-model="formData.reqCont"
                    id="reqCont"
                    type="text"
                    maxlength="2000"
                    @input="$ekmtcCommon.hideErrorTooltip('#reqCont')"
                  >
                </td>
              </tr>
              <tr>
                <th class="text_center">
                  취소 후<br>선적 예정 선박
                </th>
                <td>
                  <input
                    v-model="formData.updtVslCd"
                    placeholder="(Vessel Name) 없을 경우 공란"
                    id="updtVslCd"
                    type="text"
                    maxlength="200"
                    @input="$ekmtcCommon.onlyEngNumber($event)"
                    @keyup="$ekmtcCommon.changeUpperCase($event)"
                  />
                </td>
                <td>
                  <input
                    v-model="formData.updtVoyNo"
                    id="updtVoyNo"
                    placeholder="(Voyage Name) 없을 경우 공란"
                    type="text"
                    maxlength="6"
                    @input="$ekmtcCommon.onlyEngNumber($event)"
                    @keyup="$ekmtcCommon.changeUpperCase($event)"
                  >
                </td>
              </tr>
              <tr v-for="(vo, idx) in containerInfo" :key="'cntr_' + idx">
                <th class="text_center">
                  CNTR No.
                </th>
                <td colspan="2">
                  <input
                    v-model="vo.cntrNo"
                    :id="'cntrNo_' + idx"
                    type="text"
                    style="width:200px;"
                    maxlength="11"
                    @input="changeOnlyUpperEng($event, vo, 'cntrNo', idx)"
                    @blur="checkValidationCntrNo(idx)"
                  >
                  <button
                    v-if="idx === 0"
                    class="tbl_icon plus"
                    style="margin-left: 5px;"
                    @click.prevent="addBLContainer(idx)"
                  >
                    plus
                  </button>
                  <button
                    v-if="idx !== 0"
                    class="tbl_icon minus"
                    style="margin-left: 5px;"
                    @click.prevent="removeBLContainer(idx)"
                  >
                    minus
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text_center mt10">
          <a
            href="#none"
            class="button blue lg ml_auto mr5"
            @click.prevent="bkgCancelReqInsert()"
          >{{ $t('msg.MYIN040G010.062') }}</a><!-- 임시저장 -->
          <a
            v-show="reqTempSaveYn === 'Y'"
            href="#none"
            class="button blue lg ml_auto mr5"
            @click="bkgCancelReqDelete()"
          >{{ $t('art.CMBA660.007') }}</a> <!-- 삭제 -->
          <a
            href="#none"
            class="button gray lg"
            @click.prevent="reqBookingCancelSave()"
          >{{ $t('js.booking.044') }}</a><!-- 제출하기 -->&nbsp;
          <a class="button gray lg" @click="$emit('close')()">{{ $t('msg.ONEX010P095.057') }}</a>
        </div>
        <win-layer-pop class="popup_dim">
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closePopup"
            :parent-info="uploadParam"
            @set-dext-file-info="arrangeUploadParam"
          />
        </win-layer-pop>
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>
import docs from '@/api/rest/trans/docs'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import EInputNumber from '@/components/common/EInputNumber'
import { rootComputed } from '@/store/helpers'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'

export default {
  name: 'BookingCancelReqInputPop',
  components: {
    EInputNumber,
    DocUploadPop,
    WinLayerPop,
    rootComputed,
    EAutoCompletePlace,
    EAutoCompleteVessel: () => import('@/components/common/EAutoCompleteVessel')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: ''
        }
      }
    }
  },
  data () {
    return {
      formData: {
        bkgNo: '',
        kind: '2',
        vslCd: '',
        voyNo: '',
        polCtrCd: '',
        polPortCd: '',
        podPortCd: '',
        shprCstNo: '',
        shprCstNm: '',
        reqRsnCont: '',
        blNo: '',
        reqPicNo: 'all',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        reqPicNmS: '',
        containerList: [],
        //파일 정보
        uploadFileInfos: [],
        updtVoyNo: '',
        updtVslCd: ''
      },
      containerInfo: [
        {
          cntrSeq: '1',
          cntrNo: ''
        }
      ],
      dangerDocInfo: {
        vslCd: '',
        voyNo: '',
        polCtrCd: '',
        polPortCd: '',
        podCtrCd: '',
        podPortCd: '',
        shprCstNo: '',
        shprCstNm: '',
        reqRsnCont: '',
        blNo: ''
      },
      managerList: [],
      dgCrtfNoList: [],
      readOnly: false,
      //업로드 관련 파라미터(파일 첨부 시, 값이 담기고 CUD 시 parameter에 옮김)
      uploadParam: [],
      popupParams: {},
      customComponent: null,
      pod: '',
      pol: '',
      today: '',
      vsl: {},
      reqTempSaveYn: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // this.formData = this.parentInfo
    this.init()
    // this.getDangerDocInfo()
  },
  methods: {
    async init () {
      const TH = this
      let tempInfoYn = 'N'

      // 오늘 날짜
      let now = moment()
      let today = now.format('YYYY/MM/DD')
      this.today = today

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (this.auth.userId) {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      }

      // 임시저장 정보 불러오기
      await docs.getReqDocTempInfo(this.parentInfo)
      .then(res => {
        console.log('getReqDocTempInfo res @@@@@ ', res)
        const data = res.data
        const containerList = []

        if (data.length > 0) {
          TH.formData = data[0]
          TH.reqTempSaveYn = 'Y'
          tempInfoYn = 'Y'

          for (var item of data) {
            containerList.push({
              cntrNo: item.cntrNo
            })
          }

          TH.containerInfo = containerList
          TH.formData.containerList = containerList
        }
      })

      // 임시저장정보 없으면 부킹정보 불러오기
      if (tempInfoYn !== 'Y') {
        await this.getReqDocTempBkgInfo()
      }
    },
    async bkgCancelReqInsert () {
      console.log('codShipbackInsert @@@@@')

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN040G010.061'), useConfirmBtn: true })) {
        this.formData.containerList = this.containerInfo
        this.formData.reqCatCd = this.parentInfo.reqCatCd

       await docs.reqDocTempSubmit(this.formData)
          .then((response) => {
            console.log('reqDocTempSubmit response @@@@@@ ', response)
            if (response.headers.respcode === 'C0000') {
              const TH = this
              TH.$ekmtcCommon.alertCallback('저장되었습니다.', function () {
                TH.reqTempSaveYn = 'Y'
              })
          } else {
            TH.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.237')) // 요청 접수에 실패하였습니다.
          }
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          // this.$emit('close', false)
        })
      } else {
        return false
      }
    },
    async bkgCancelReqDelete () {
      console.log('bkgCancelReqDelete @@@@@')

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT023'), useConfirmBtn: true })) {
        this.formData.containerList = this.containerInfo
        this.formData.reqCatCd = this.parentInfo.reqCatCd

        docs.reqDocTempDelete(this.formData)
          .then((response) => {
            console.log('bkgCancelReqDelete response @@@@@@ ', response)
            if (response.headers.respcode === 'C0000') {
              const TH = this
              TH.$ekmtcCommon.alertCallback(TH.$t('tem.CMAT012'), function () {
                TH.reqTempSaveYn = 'N'

                // 임시저장정보 삭제됬으니 부킹정보조회
                TH.getReqDocTempBkgInfo()
              })
          } else {
            TH.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.237')) // 요청 접수에 실패하였습니다.
          }
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          // this.$emit('close', false)
        })
      } else {
        return false
      }
    },
    async reqBookingCancelSave () {
      let isOk = true
      const TH = this
      const formData = TH.formData

      if (!await this.checkValidationBkgCancelAll()) {
        isOk = false
      }

      if (isOk) {
        let successFlag = true
        this.formData.containerList = this.containerInfo
        this.formData.reqCatCd = this.parentInfo.reqCatCd
        await docs.reqDocTempSubmit(this.formData)
          .then((response) => {
            console.log('reqDocTempSubmit response @@@@@@ ', response)
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          // this.$emit('close', false)
        })

        formData.vslCd = TH.formData.vslCd
        formData.voyNo = TH.formData.voyNo
        formData.polCtrCd = TH.formData.polCtrCd
        formData.polPortCd = TH.formData.polPortCd
        formData.podCtrCd = TH.formData.podCtrCd
        formData.podPortCd = TH.formData.podPortCd
        formData.shprCstNo = TH.formData.shprCstNo
        formData.shprCstNm = TH.formData.shprCstNm
        formData.blNo = TH.parentInfo.blNo
        formData.bkgNo = TH.formData.bkgNo
        formData.reqCatCd = '01'

        let result = null
         await docs.reqDocSubmit(formData).then(res => {
           if (res.headers.respcode !== 'C0000') {
             successFlag = false
           }
           result = res.data
         }).catch(() => {
           successFlag = false
         }).finally(() => {
           if (successFlag && result !== null) {
             TH.$ekmtcCommon.alertCallback(TH.$t('msg.CSBK100.236'), function () {
               TH.$emit('selectFunc', result)
               TH.$emit('close')
             })
           } else {
             TH.$ekmtcCommon.alertDefault(TH.$t('msg.CSBK100.237'))
           }
         })
      }
    },
    openPopup () {
        this.customComponent = DocUploadPop
        this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    //파일첨부화면과 팝업을 나누기 위해서 설정
    closePopup () {
      console.log('---  closePopup')
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    addBLContainer () {
      const containerList = this.containerInfo
      const containerLen = containerList.length
      const cntrSeq = containerLen === 0 ? 1 : Number(containerList[containerLen - 1].cntrSeq) + 1
      containerList.push(
        {
            cntrSeq: cntrSeq,
            cntrNo: ''
          }
      )
    },
    removeBLContainer (idx) {
      console.log('removeBLContainer idx @@@@@ ', idx)
      const containerList = this.containerInfo
      containerList.splice(idx, 1)

      const containerLen = containerList.length

      if (containerLen === 0) {
        this.addBLContainer()
      }
    },
    changeOnlyUpperEng (e, object, key, idx) {
      let key2 = key
      if (key === 'cntrNo') {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#cntrNo_' + idx)
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      } else if (key && key.startsWith('sealNo')) { // seal No
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector(`[name="${key}_${idx}"]`) // name으로 검색
        key2 = 'sealNo'
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      }
      this.$ekmtcCommon.onlyEngNumber(e)
      this.$set(object, key2, object[key2].toUpperCase())
    },
    changeVsl (vo) {
      this.vsl.vslNm = vo.cdNm
      this.vsl.vslCd = vo.cd
      this.formData.updtVslCd = vo.cd
      console.log('>>>> [Vessel] vesselNm : ' + this.vsl.vslNm + ' / ' + this.vsl.vslCd)
    },
    async checkValidationCntrNo (idx) {
      let isOk = true
      const TH = this
      const formData = this.formData
      const containerInfo = this.containerInfo
      const frm = document.querySelector('#frm')
      const selector = document.querySelector('#cntrNo_' + idx)
      const cntrInfo = this.containerInfo[idx]

      this.$ekmtcCommon.hideErrorTooltip(selector)

      const params = {
        cntrNo: cntrInfo.cntrNo,
        bkgNo: formData.bkgNo,
        blNo: formData.blNo,
        type: formData.bkgNo !== '' ? 'BK' : 'BL'
      }
      params.cntrKind = 'BF'
      await docs.checkCntrInBlBkg(params)
        .then(res => {
          const data = res.data

          if (!TH.$ekmtcCommon.isEmpty(data.cntrNo)) {
            containerInfo.forEach((c, i) => {
              if (i !== idx && c.cntrNo === cntrInfo.cntrNo) {
                TH.$ekmtcCommon.showErrorTooltip(selector, TH.$t('art.CMATK177'))
                isOk = false
              }
            })
          } else {
            console.log('selector @@@@@ ', selector)

            TH.$ekmtcCommon.showErrorTooltip(selector, TH.$t('art.CMATK363'))
            isOk = false
          }
        })

      return isOk
    },
    async checkValidationBkgCancelAll () {
      let isOk = true
      const formData = this.formData
      const prefix = ['shprCstEml', 'shprCstTelNo', 'reqCont']

      prefix.forEach((el, idx) => {
        if (!this.checkValidationInputInfo(el, idx)) {
          isOk = false
        }
      })

      for (const [idx, val] of this.containerInfo.entries()) {
        const checkCntr = await this.checkValidationCntrNo(idx)

        if (!checkCntr) {
          isOk = false
        }
      }

      return isOk
    },
    checkValidationEmail (id) {
      console.log('checkValidationEmail id @@@@@', id)

      const frm = document.querySelector('#frm')
      const selector = document.querySelector('#' + id)

      if (selector === null) {
        return true
      }

      let isOk = true
      if (selector !== undefined && selector !== null && this.$ekmtcCommon.isNotEmpty(selector.value)) {
        if (selector.value.indexOf(',') > -1) {
          if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
            isOk = false
          }
        } else {
          if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            isOk = false
          }
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.VOSD100.043')) // 이메일 체크
      }

      return isOk
    },
    checkErrorVisible (id) {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#' + id)

      const parentElem = $(elem).parents('td, div').eq(0)
      const msgBox = parentElem.find('.tooltip_essen_box').eq(0)

      if (msgBox.length > 0 && msgBox.is(':visible') && this.$ekmtcCommon.isNotEmpty(elem.value)) {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    },
    async getReqDocTempBkgInfo () {
      const TH = this

      await docs.getReqDocTempBkgInfo(this.parentInfo)
        .then(res => {
          console.log('getReqDocTempBkgInfo res @@@@@ ', res)
          const data = res.data
          data.containerList = []

          TH.formData = data

          // 컨테이너 입력칸 한줄추가
          if (TH.containerInfo.length === 0) {
            this.addBLContainer()
          }
        })
    },
    // 선택 밸리데이션
    checkValidationInputInfo (el, idx) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const selector = document.querySelector('#' + el)
      let selectorVal

      if (el === 'shprCstEml') {
        selectorVal = this.formData.shprCstEml
      } else if (el === 'shprCstTelNo') {
        selectorVal = this.formData.shprCstTelNo
      } else {
        selectorVal = this.formData.reqCont
      }

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (selector !== null && this.$ekmtcCommon.isEmpty(selectorVal)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      return isOk
    }
  }
}
</script>

<style scoped>
text_center {
  text-align: center;
}
</style>
